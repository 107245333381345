export const iconForward1000 = (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.4202 2.04883V8.14679H15.3223" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.8691 13.2272C18.2085 15.0971 16.958 16.7017 15.306 17.7991C13.6541 18.8965 11.6903 19.4273 9.71042 19.3115C7.73057 19.1957 5.842 18.4395 4.3293 17.157C2.8166 15.8744 1.76172 14.135 1.32363 12.2007C0.885529 10.2665 1.08795 8.24227 1.90039 6.43308C2.71283 4.6239 4.09126 3.12778 5.82798 2.17017C7.56469 1.21256 9.5656 0.845346 11.5292 1.12386C13.4927 1.40238 15.3126 2.31154 16.7145 3.71434L21.4201 8.14553" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.408 12.36V13H6.536V12.36H7.672V9.24C7.61333 9.32 7.50667 9.41067 7.352 9.512C7.19733 9.608 7.02933 9.69067 6.848 9.76C6.66667 9.82933 6.50667 9.864 6.368 9.864V9.192C6.48533 9.192 6.61333 9.16 6.752 9.096C6.896 9.02667 7.03467 8.94667 7.168 8.856C7.30133 8.76533 7.41333 8.68 7.504 8.6C7.6 8.52 7.656 8.464 7.672 8.432H8.392V12.36H9.408ZM11.6016 13.08C11.2549 13.08 10.9296 13.024 10.6256 12.912C10.3216 12.7947 10.0602 12.6213 9.84156 12.392L10.1136 11.896C10.3536 12.12 10.5936 12.28 10.8336 12.376C11.0736 12.472 11.3189 12.52 11.5696 12.52C11.8576 12.52 12.0922 12.4667 12.2736 12.36C12.4549 12.248 12.5456 12.0853 12.5456 11.872C12.5456 11.7173 12.4976 11.6027 12.4016 11.528C12.3109 11.4533 12.1802 11.392 12.0096 11.344C11.8389 11.2907 11.6362 11.232 11.4016 11.168C10.9696 11.0453 10.6389 10.9013 10.4096 10.736C10.1856 10.5653 10.0736 10.3173 10.0736 9.992C10.0736 9.59733 10.2229 9.29333 10.5216 9.08C10.8256 8.86133 11.2122 8.752 11.6816 8.752C12.3109 8.752 12.8069 8.94133 13.1696 9.32L12.8496 9.808C12.5242 9.472 12.1216 9.304 11.6416 9.304C11.4976 9.304 11.3562 9.32267 11.2176 9.36C11.0789 9.392 10.9642 9.45333 10.8736 9.544C10.7829 9.62933 10.7376 9.752 10.7376 9.912C10.7376 10.1093 10.8176 10.2507 10.9776 10.336C11.1376 10.4213 11.3722 10.5093 11.6816 10.6C11.9962 10.6907 12.2682 10.7813 12.4976 10.872C12.7322 10.9627 12.9136 11.08 13.0416 11.224C13.1696 11.368 13.2336 11.568 13.2336 11.824C13.2336 12.2187 13.0842 12.528 12.7856 12.752C12.4869 12.9707 12.0922 13.08 11.6016 13.08Z" fill="#0047FF"/>
  </svg>
);

export const iconForward100 = (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1605 2.04883V8.14679H16.0625" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.6094 13.2272C18.9487 15.0971 17.6982 16.7017 16.0463 17.7991C14.3944 18.8965 12.4305 19.4273 10.4507 19.3115C8.47081 19.1957 6.58224 18.4395 5.06954 17.157C3.55683 15.8744 2.50196 14.135 2.06386 12.2007C1.62576 10.2665 1.82819 8.24227 2.64062 6.43308C3.45306 4.6239 4.8315 3.12778 6.56821 2.17017C8.30493 1.21256 10.3058 0.845346 12.2694 1.12386C14.233 1.40238 16.0528 2.31154 17.4548 3.71434L22.1604 8.14553" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.512 13V12.104H7.104V13H6.512ZM11.0252 12.36V13H8.15319V12.36H9.28919V9.24C9.23052 9.32 9.12385 9.41067 8.96919 9.512C8.81452 9.608 8.64652 9.69067 8.46519 9.76C8.28385 9.82933 8.12385 9.864 7.98519 9.864V9.192C8.10252 9.192 8.23052 9.16 8.36919 9.096C8.51319 9.02667 8.65185 8.94667 8.78519 8.856C8.91852 8.76533 9.03052 8.68 9.12119 8.6C9.21719 8.52 9.27319 8.464 9.28919 8.432H10.0092V12.36H11.0252ZM13.2188 13.08C12.8721 13.08 12.5468 13.024 12.2428 12.912C11.9388 12.7947 11.6774 12.6213 11.4588 12.392L11.7308 11.896C11.9708 12.12 12.2108 12.28 12.4508 12.376C12.6908 12.472 12.9361 12.52 13.1868 12.52C13.4748 12.52 13.7094 12.4667 13.8908 12.36C14.0721 12.248 14.1628 12.0853 14.1628 11.872C14.1628 11.7173 14.1148 11.6027 14.0188 11.528C13.9281 11.4533 13.7974 11.392 13.6268 11.344C13.4561 11.2907 13.2534 11.232 13.0188 11.168C12.5868 11.0453 12.2561 10.9013 12.0268 10.736C11.8028 10.5653 11.6908 10.3173 11.6908 9.992C11.6908 9.59733 11.8401 9.29333 12.1388 9.08C12.4428 8.86133 12.8294 8.752 13.2988 8.752C13.9281 8.752 14.4241 8.94133 14.7868 9.32L14.4668 9.808C14.1414 9.472 13.7388 9.304 13.2588 9.304C13.1148 9.304 12.9734 9.32267 12.8348 9.36C12.6961 9.392 12.5814 9.45333 12.4908 9.544C12.4001 9.62933 12.3548 9.752 12.3548 9.912C12.3548 10.1093 12.4348 10.2507 12.5948 10.336C12.7548 10.4213 12.9894 10.5093 13.2988 10.6C13.6134 10.6907 13.8854 10.7813 14.1148 10.872C14.3494 10.9627 14.5308 11.08 14.6588 11.224C14.7868 11.368 14.8508 11.568 14.8508 11.824C14.8508 12.2187 14.7014 12.528 14.4028 12.752C14.1041 12.9707 13.7094 13.08 13.2188 13.08Z" fill="#0047FF"/>
  </svg>
);

export const iconBackward100 = (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.83953 2.04883V8.14679H7.9375" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.39063 13.2272C5.05128 15.0971 6.30179 16.7017 7.95372 17.7991C9.60565 18.8965 11.5695 19.4273 13.5493 19.3115C15.5292 19.1957 17.4178 18.4395 18.9305 17.157C20.4432 15.8744 21.498 14.135 21.9361 12.2007C22.3742 10.2665 22.1718 8.24227 21.3594 6.43308C20.5469 4.6239 19.1685 3.12778 17.4318 2.17017C15.6951 1.21256 13.6942 0.845346 11.7306 1.12386C9.76702 1.40238 7.94715 2.31154 6.54524 3.71434L1.83965 8.14553" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.512 13V12.104H10.104V13H9.512ZM14.0252 12.36V13H11.1532V12.36H12.2892V9.24C12.2305 9.32 12.1239 9.41067 11.9692 9.512C11.8145 9.608 11.6465 9.69067 11.4652 9.76C11.2839 9.82933 11.1239 9.864 10.9852 9.864V9.192C11.1025 9.192 11.2305 9.16 11.3692 9.096C11.5132 9.02667 11.6519 8.94667 11.7852 8.856C11.9185 8.76533 12.0305 8.68 12.1212 8.6C12.2172 8.52 12.2732 8.464 12.2892 8.432H13.0092V12.36H14.0252ZM16.2188 13.08C15.8721 13.08 15.5468 13.024 15.2428 12.912C14.9388 12.7947 14.6774 12.6213 14.4588 12.392L14.7308 11.896C14.9708 12.12 15.2108 12.28 15.4508 12.376C15.6908 12.472 15.9361 12.52 16.1868 12.52C16.4748 12.52 16.7094 12.4667 16.8908 12.36C17.0721 12.248 17.1628 12.0853 17.1628 11.872C17.1628 11.7173 17.1148 11.6027 17.0188 11.528C16.9281 11.4533 16.7974 11.392 16.6268 11.344C16.4561 11.2907 16.2534 11.232 16.0188 11.168C15.5868 11.0453 15.2561 10.9013 15.0268 10.736C14.8028 10.5653 14.6908 10.3173 14.6908 9.992C14.6908 9.59733 14.8401 9.29333 15.1388 9.08C15.4428 8.86133 15.8294 8.752 16.2988 8.752C16.9281 8.752 17.4241 8.94133 17.7868 9.32L17.4668 9.808C17.1414 9.472 16.7388 9.304 16.2588 9.304C16.1148 9.304 15.9734 9.32267 15.8348 9.36C15.6961 9.392 15.5814 9.45333 15.4908 9.544C15.4001 9.62933 15.3548 9.752 15.3548 9.912C15.3548 10.1093 15.4348 10.2507 15.5948 10.336C15.7548 10.4213 15.9894 10.5093 16.2988 10.6C16.6134 10.6907 16.8854 10.7813 17.1148 10.872C17.3494 10.9627 17.5308 11.08 17.6588 11.224C17.7868 11.368 17.8508 11.568 17.8508 11.824C17.8508 12.2187 17.7014 12.528 17.4028 12.752C17.1041 12.9707 16.7094 13.08 16.2188 13.08Z" fill="#0047FF"/>
  </svg>
);

export const iconBackward1000 = (
  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.57977 2.04883V8.14679H7.67773" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.13086 13.2272C4.79151 15.0971 6.04202 16.7017 7.69395 17.7991C9.34588 18.8965 11.3097 19.4273 13.2896 19.3115C15.2694 19.1957 17.158 18.4395 18.6707 17.157C20.1834 15.8744 21.2383 14.135 21.6764 12.2007C22.1145 10.2665 21.912 8.24227 21.0996 6.43308C20.2872 4.6239 18.9087 3.12778 17.172 2.17017C15.4353 1.21256 13.4344 0.845346 11.4708 1.12386C9.50725 1.40238 7.68739 2.31154 6.28548 3.71434L1.57988 8.14553" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.408 12.36V13H10.536V12.36H11.672V9.24C11.6133 9.32 11.5067 9.41067 11.352 9.512C11.1973 9.608 11.0293 9.69067 10.848 9.76C10.6667 9.82933 10.5067 9.864 10.368 9.864V9.192C10.4853 9.192 10.6133 9.16 10.752 9.096C10.896 9.02667 11.0347 8.94667 11.168 8.856C11.3013 8.76533 11.4133 8.68 11.504 8.6C11.6 8.52 11.656 8.464 11.672 8.432H12.392V12.36H13.408ZM15.6016 13.08C15.2549 13.08 14.9296 13.024 14.6256 12.912C14.3216 12.7947 14.0602 12.6213 13.8416 12.392L14.1136 11.896C14.3536 12.12 14.5936 12.28 14.8336 12.376C15.0736 12.472 15.3189 12.52 15.5696 12.52C15.8576 12.52 16.0922 12.4667 16.2736 12.36C16.4549 12.248 16.5456 12.0853 16.5456 11.872C16.5456 11.7173 16.4976 11.6027 16.4016 11.528C16.3109 11.4533 16.1802 11.392 16.0096 11.344C15.8389 11.2907 15.6362 11.232 15.4016 11.168C14.9696 11.0453 14.6389 10.9013 14.4096 10.736C14.1856 10.5653 14.0736 10.3173 14.0736 9.992C14.0736 9.59733 14.2229 9.29333 14.5216 9.08C14.8256 8.86133 15.2122 8.752 15.6816 8.752C16.3109 8.752 16.8069 8.94133 17.1696 9.32L16.8496 9.808C16.5242 9.472 16.1216 9.304 15.6416 9.304C15.4976 9.304 15.3562 9.32267 15.2176 9.36C15.0789 9.392 14.9642 9.45333 14.8736 9.544C14.7829 9.62933 14.7376 9.752 14.7376 9.912C14.7376 10.1093 14.8176 10.2507 14.9776 10.336C15.1376 10.4213 15.3722 10.5093 15.6816 10.6C15.9962 10.6907 16.2682 10.7813 16.4976 10.872C16.7322 10.9627 16.9136 11.08 17.0416 11.224C17.1696 11.368 17.2336 11.568 17.2336 11.824C17.2336 12.2187 17.0842 12.528 16.7856 12.752C16.4869 12.9707 16.0922 13.08 15.6016 13.08Z" fill="#0047FF"/>
  </svg>
);

export const iconPlay = (
  <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L16 11.5L1 22V1Z" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const iconPause = (
  <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1V22M16 22V1" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const iconMiniCheckboxChecked = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="18" height="18" stroke="#0047FF" strokeWidth="2"/>
  <path d="M14.6155 6.92188L8.26938 13.845L5.38477 10.6981" stroke="#0047FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const iconMiniCheckboxUnchecked = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="18" height="18" stroke="#BCBCBC" strokeWidth="2"/>
  </svg>
);
